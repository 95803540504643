import React from 'react'
import PropTypes from 'prop-types'

import SelectLanguage from './SelectLanguage'

const Footer = props => (
  <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
    <div className="copyright">
      &copy;{props.data.copyright}
      &nbsp;/&nbsp;<span className="icon fa-globe"></span>&nbsp;
      <SelectLanguage lang={props.lang} />
    </div>
  </footer>
)

Footer.propTypes = {
  timeout: PropTypes.bool,
  data: PropTypes.object,
  lang: PropTypes.string,
}

export default Footer
