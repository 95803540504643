import React from 'react'
import PropTypes from 'prop-types'

import Contact from './Contact'

import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Articles extends React.Component {
  render() {
    const pic = [pic01, pic02, pic03]

    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        {this.props.data.articles.map((article, i) => (
          <article
            key={i}
            id={article.title}
            className={`${
              this.props.article === article.title ? 'active' : ''
            } ${this.props.articleTimeout ? 'timeout' : ''}`}
            style={{ display: 'none' }}
          >
            <h2 className="major">{article.title}</h2>
            <span className="image main">
              <img src={pic[i]} alt={article.title} />
            </span>
            <p dangerouslySetInnerHTML={{ __html: article.text }}></p>
            {close}
          </article>
        ))}

        <Contact
          data={this.props.data.contact}
          close={close}
          article={this.props.article}
          articleTimeout={this.props.articleTimeout}
        />
      </div>
    )
  }
}

Articles.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
  data: PropTypes.object,
}

export default Articles
