import React from 'react'
import PropTypes from 'prop-types'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="icon fa-code"></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>{props.data.title}</h1>
        <p dangerouslySetInnerHTML={{ __html: props.data.introduction }}></p>
      </div>
    </div>
    <nav>
      <ul>
        {props.data.menu.map((menu, i) => (
          <li key={i}>
            <button
              onClick={() => {
                props.onOpenArticle(menu)
              }}
            >
              {menu}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
  data: PropTypes.object,
}

export default Header
