import React from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import validator from 'email-validator'

const URL =
  'https://acdx69s8v8.execute-api.eu-west-3.amazonaws.com/dev/sendemail'
//const URL = "http://localhost:3000/sendemail"
const ApiKey = 'aFiJlzLiJK3rdhqVrDm4C7nYpOS8PHET2auBmY7w'
//const ApiKey = 'd41d8cd98f00b204e9800998ecf8427e'

class Contact extends React.Component {
  constructor() {
    super()
    // recaptcha
    this.recaptchaInstance = React.createRef()
  }

  state = {
    name: '',
    email: '',
    message: '',
  }

  _handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  handleResetForm = () => {
    this.setState({
      name: '',
      email: '',
      message: '',
    })
    document.querySelector('#submit').disabled = false
    document.getElementById('email').style.borderColor = 'white'
    this.recaptchaInstance.current.reset()
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })

    if (name === 'email') {
      if (validator.validate(value)) {
        document.getElementById('email').style.borderColor = 'green'
      } else {
        document.getElementById('email').style.borderColor = 'red'
      }
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    console.log('Submit ...')
    if (validator.validate(this.state.email)) {
      console.log('Email validate !')
      this.recaptchaInstance.current.execute()
    } else {
      console.log('Email not valide !')
      document.getElementById('email').style.borderColor = 'red'
    }
  }

  recaptchaCallback = () => {
    console.log('Envoi du mail...')

    let param = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': ApiKey,
      },
      body: JSON.stringify({
        email: this.state,
        recaptcha: this.recaptchaInstance.current.getValue(),
      }),
    }
    fetch(URL, param)
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.error) {
          document.querySelector('#submit').value = 'error'
        } else {
          document.querySelector('#submit').value = 'sended'
        }
        document.querySelector('#submit').disabled = true
      })
      .catch(error => {
        console.log(error)
        document.querySelector('#submit').value = 'error'
        document.querySelector('#submit').disabled = true
      })
  }

  render() {
    return (
      <article
        id="Contact"
        className={`${this.props.article === 'Contact' ? 'active' : ''} ${
          this.props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">{this.props.data.title}</h2>
        <form onSubmit={this.handleSubmit}>
          <div className="field half first">
            <label htmlFor="name">{this.props.data.name}</label>
            <input
              type="text"
              name="name"
              id="name"
              value={this.state.name}
              onChange={this.handleInputChange}
              onKeyPress={this._handleKeyPress}
            />
          </div>
          <div className="field half">
            <label htmlFor="email">{this.props.data.email}</label>
            <input
              type="text"
              name="email"
              id="email"
              value={this.state.email}
              onChange={this.handleInputChange}
              onKeyPress={this._handleKeyPress}
            />
          </div>
          <div className="field">
            <label htmlFor="message">{this.props.data.message}</label>
            <textarea
              name="message"
              id="message"
              rows="4"
              value={this.state.message}
              onChange={this.handleInputChange}
            />
          </div>
          <ul className="actions">
            <li>
              <input
                type="submit"
                value={this.props.data.send}
                className="special"
                id="submit"
              />
            </li>
            <li>
              <input
                type="reset"
                value={this.props.data.reset}
                onClick={this.handleResetForm}
              />
            </li>
          </ul>
        </form>
        <ul className="icons">
          {this.props.data.sociaux.map((social, i) => (
            <li key={i}>
              <a href={social.url} className={social.icon}>
                <span className="label">{social.name}</span>
              </a>
            </li>
          ))}
        </ul>
        {this.props.close}

        <div style={style}>
          <ReCAPTCHA
            ref={this.recaptchaInstance}
            sitekey="6Lch2pcUAAAAAK-uglxyW9ljSwhgJLXTlxYVEjkY"
            size="invisible"
            onChange={this.recaptchaCallback}
          />
        </div>
      </article>
    )
  }
}

const style = {
  display: 'none',
}

Contact.propTypes = {
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  close: PropTypes.object,
  data: PropTypes.object,
}

export default Contact
