import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'

import languages from '../locales/languages'

const SelectLanguage = props => {
  const links = languages.langs.map(lang => (
    <li selected={lang === props.lang ? 'selected' : ''} key={lang}>
      <Link to={lang !== languages.defaultLangKey ? lang : '/'}>{lang}</Link>
    </li>
  ))

  return (
    <span id="selectLanguage">
      <ul>{links}</ul>
    </span>
  )
}

SelectLanguage.propTypes = {
  lang: PropTypes.string,
}

export default SelectLanguage
